var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"d-flex flex-column align-center full-height full-width"},[_c('PageTitle',{attrs:{"icon":"mdi-receipt","title":_vm.$tc('Views.v-44'),"loading":_vm.loader}}),_c('v-row',{class:_vm.vouchers.length >= 0 && !_vm.loader
        ? 'full-width flex-grow-0 mb-12'
        : 'full-width flex-grow-1 mb-12'},[_c('v-col',{staticClass:"d-flex justify-center"},[_c('v-card',{staticClass:"custom-card",attrs:{"elevation":"12"}},[(_vm.loader)?_c('Loader',{attrs:{"message":_vm.$tc('Views.v-45')}}):(!_vm.loader)?_c('div',[_c('v-data-table',{staticClass:"customDT",attrs:{"headers":_vm.headers,"items":_vm.vouchers,"server-items-length":_vm.pagination.totalItems,"loading":_vm.loadingDT,"footer-props":{
              itemsPerPageText: _vm.$tc('Views.v-46'),
              pageText: ("{0}-{1} " + (_vm.$tc('Newsletter.newslettersByPage')) + " {2}"),
              'items-per-page-all-text': _vm.$tc('Newsletter.allNewsletters'),
            },"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"color":"info","indeterminate":"","absolute":""}})]},proxy:true},{key:"no-data",fn:function(){return [_c('p',{staticClass:"ma-6"},[_vm._v(" "+_vm._s(_vm.$tc("Views.v-47"))+" ")])]},proxy:true},{key:"loading",fn:function(){return [_c('p',{staticClass:"ma-6"},[_vm._v(_vm._s(_vm.$tc("Views.v-48")))])]},proxy:true},{key:"item.date",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatedDate(item.date))+" ")]}},{key:"item.client",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.withdrawal.client.email)+" ")]}},{key:"item.amount",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.amount))+" ")]}},{key:"item.platform",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.platform.name)+" ")]}},{key:"item.file",fn:function(ref){
            var item = ref.item;
return [_c('a',{staticClass:"customLink cta--text",attrs:{"href":item.file.url,"download":"","target":"_blank"}},[_vm._v(_vm._s(_vm.$tc("Views.v-49")))])]}}],null,true)})],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }