






































































import "vue-inner-image-zoom/lib/vue-inner-image-zoom.css";
import { FormValidations } from "@/mixins/form-validations";
import { Navigation } from "@/mixins/navigation";
import { Permissions } from "@/mixins/permissions";
import { CustomDate } from "@/mixins/custom-date";
import PageTitle from "@/components/General/PageTitle.vue";
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import Loader from "@/components/General/Loader.vue";
import Empty from "@/components/General/Empty.vue";
import { Notification } from "@/models/notification.interface";
import InnerImageZoom from "vue-inner-image-zoom";

@Component({
  components: { PageTitle, Loader, Empty, "inner-image-zoom": InnerImageZoom },
})
export default class Vouchers extends mixins(
  FormValidations,
  Navigation,
  Permissions,
  CustomDate
) {
  loader = false;
  loadingDT = false;
  options = {};

  pagination = {
    itemsPerPage: parseInt(process.env.VUE_APP_DEFAULT_PAGINATION),
    totalItems: 0,
    page: 1,
  };

  @Watch("options", { deep: true })
  private async setItems(pagination) {
    this.loadingDT = true;
    this.pagination.itemsPerPage = pagination.itemsPerPage;

    await this.getVouchers(
      pagination.page,
      pagination.itemsPerPage == -1
        ? this.pagination.totalItems
        : this.pagination.itemsPerPage
    );
    this.loadingDT = false;
  }

  private async getVouchers(page: number, size: number): Promise<void> {
    if (!(isNaN(page) || isNaN(size))) {
      await this.$store
        .dispatch("withdrawal/getVouchers", { page, size })
        .catch(() => {
          const Error: Notification = {
            message: this.$tc("Views.v-e4"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: true,
          };

          this.$store.dispatch("notifications/showNotification", Error);
        });
    }
  }

  private get headers() {
    return [
      {
        text: this.$tc("Views.p-21"),
        value: "client",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Views.s-h2"),
        value: "amount",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Views.s-h3"),
        value: "date",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Views.v-50"),
        value: "platform",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Views.v-49"),
        value: "file",
        sortable: false,
        align: "center",
      },
    ];
  }

  private get vouchers(): any[] {
    let response = this.$store.getters["withdrawal/getVouchers"];
    this.pagination.totalItems = response.totalItems;
    this.pagination.page = parseInt(response.currentPage);

    if (response?.vouchers) {
      return response?.vouchers;
    } else {
      return [];
    }
  }
}
